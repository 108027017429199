<template>
  <div style="width: 100%;">
    <v-container fluid>
      <v-row>
        <v-col class="d-flex flex-row">
          <h1>Dispatch Management</h1>
          <v-progress-circular
              indeterminate
              color="green"
              v-if="loader"
              style="margin-left: 10px;"
          ></v-progress-circular>
        </v-col>
      </v-row>
      <v-row v-if="getGlobalValue('VEC_INCLUDES_DISPATCH_SERIAL_NUMBERS_STORAGE_AND_QUERYING')==='true'">
        <v-col>
          <v-text-field style="width: 500px;" dense outlined :label="`Search ${serialNaming.plural}`" v-model="serialSearch"></v-text-field>
          <v-btn color=info small @click="searchSerial" :disabled="!serialSearch || !serialSearch.trim()">Search</v-btn>
          <span v-if="serialSearchResults && serialSearchResults.length > 0" class="d-flex flex-column">
                <b>Found {{ serialSearchResults.reduce((a,b) => a+b.orders.length, 0) }} {{ serialSearchResults.reduce((a,b) => a+b.orders.length, 0) > 1 ? "documents" : "document" }} from {{ serialSearchResults.length }} {{ serialSearchResults.length > 1 ? "products" : "product" }} containing the term "{{ serialSearch }}"</b>
                <span v-for="(result, index) in serialSearchResults" :key="index">
                  <hr class="mt-2">
                  <b>Product #{{result.Product.id}} | {{result.Product.name}}</b>
                  <span class="d-flex flex-row flex-wrap">
                    <v-btn v-for="(order, o) of result.orders" :key="o" class="mr-2 mt-1" small @click="openSerialDialog({id: order.orderId})">Order #{{ order.orderId }}</v-btn>
                  </span>
                </span>
              </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex flex-row align-center">
          <h2>Orders</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card>
            <v-card-text class="">
              <span class="d-flex flex-row">
                <v-checkbox
                    v-model="allBranchesSelected"
                    label="All"
                    :value="0"
                    @click="updateBranchFilter(0)"
                />
                <span class="d-flex flex-row ml-5">
                  <v-checkbox
                      v-for="(branch, i) of allBranches"
                      :key="i"
                      v-model="options.selectedBranches"
                      :label="branch.label"
                      :value="branch.value"
                      class="mr-5"
                      @click="updateBranchFilter(branch.value)"
                  ></v-checkbox>
                </span>
              </span>
            </v-card-text>
            <v-data-table
                :headers="headers"
                :items="orders"
                :options.sync="options"
                :server-items-length="pagination.dataCount"
                :loading="pagination.loading"
                :items-per-page="15"
                class="elevation-1"
                :footer-props="{
                  itemsPerPageOptions: pagination.pageSizeOptions
                }"
            >
              <template v-slot:item.customerName="{ item }">
                <span>{{ item.Customer?(item.Customer.name?item.Customer.name:(item.Customer.firstName||item.Customer.lastName?(`${item.Customer.firstName||''} ${item.Customer.lastName||''}`):'-')):"-" }}</span>
              </template>
              <template v-slot:item.customerPhone="{ item }">
                <span>{{ item.Customer?.phone||'-' }}</span>
              </template>
              <template v-slot:item.csrUser="{ item }">
                <span>{{item.createdBy?lookupUsername(item.createdBy):'-'}}</span>
              </template>
              <template v-slot:item.address="{ item }">
                <span class="">{{[item.deliveryInfo.address.line1, item.deliveryInfo.address.line2, item.deliveryInfo.address.city, item.deliveryInfo.address.country].filter(x => x && x.trim().length>0).join(",\n")}}</span>
              </template>
              <template v-slot:item.status="{ item }">
                <v-chip :color="utils.getDispatchStatusColor(item.deliveryInfo.status)">{{utils.parseDispatchStatus(item.deliveryInfo.status)}}</v-chip>
              </template>
              <template v-slot:item.sealedAt="{ item }">
                <span>{{utils.formatDate(item.sealedAt, 'short')}}</span>
              </template>
              <template v-slot:item.branchId="{ item }">
                <span>{{lookupBranch(item.branchId)}}</span>
              </template>
              <template v-slot:item.sealedBy="{ item }">
                <span>{{lookupUsername(item.sealedBy)}}</span>
              </template>
              <template v-slot:item.actions="{ item }">
                <span>
                  <v-btn class="mr-1" x-small fab color="warning" v-if="!item.deliveryInfo.status || item.deliveryInfo.status===0" :loading="item.isLoading" @click="openSerialDialog(item)"><v-icon>mdi-barcode</v-icon></v-btn>
                  <v-btn class="mr-1" x-small fab color="success" v-if="item.deliveryInfo.status===1" @click="openSerialDialog(item)"><v-icon>mdi-package-variant-closed-check</v-icon></v-btn>
                  <v-btn class="mr-1" x-small fab color="info" v-if="item.deliveryInfo.status>=2" @click="openSerialDialog(item)"><v-icon>mdi-eye</v-icon></v-btn>
                </span>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="serialDialog.isOpen">
      <v-card>
        <v-card-title>
          <span>Enter {{serialNaming.plural}} and Print Delivery Receipt</span>
          <v-progress-circular class="ml-2" indeterminate color="success" v-if="serialDialog.isSyncing"/>
        </v-card-title>
        <v-card-text v-if="serialDialog.data" class="d-flex flex-column">
          <span class="d-flex flex-column">
            <span v-if="serialDialog.data.deliveryInfo.sentOutBy && serialDialog.data.deliveryInfo.sentOutAt">Delivery sent out by {{lookupUsername(serialDialog.data.deliveryInfo.sentOutBy)}} at {{utils.formatDate(serialDialog.data.deliveryInfo.sentOutAt, 'withTime')}}</span>
            <span v-if="serialDialog.data.deliveryInfo.markedAsCompleteBy && serialDialog.data.deliveryInfo.markedAsCompleteAt">Delivery marked as complete by {{lookupUsername(serialDialog.data.deliveryInfo.markedAsCompleteBy)}} at {{utils.formatDate(serialDialog.data.deliveryInfo.markedAsCompleteAt, 'withTime')}}</span>
          </span>
          <h3>Order #{{serialDialog.data.id}}</h3>
          <span class="d-flex flex-row">
            <span class="d-flex flex-column pa-3" style="border: 1px solid #eee; border-radius: 5px; width: 300px;">
              <span><b>ID: </b>{{serialDialog.data.Customer?.id||'-'}}</span>
              <span><b>Name: </b>{{serialDialog.data.Customer?.name||'-'}}</span>
              <span><b>Address: </b>{{[serialDialog.data.deliveryInfo.address.line1, serialDialog.data.deliveryInfo.address.line2, serialDialog.data.deliveryInfo.address.city, serialDialog.data.deliveryInfo.address.country].filter(x => x && x.trim().length>0).join(", ")||'-'}}</span>
              <span><b>Phone: </b>{{serialDialog.data.Customer?.phone||'-'}}</span>
              <span><b>Email: </b>{{serialDialog.data.Customer?.email||'-'}}</span>
            </span>
            <span class="ml-2 pa-3 d-flex flex-column" style="border: 1px solid #eee; border-radius: 5px; width: 500px;">
              <v-text-field label="Driver" outlined dense hide-details class="mb-1" v-model="serialDialog.data.deliveryInfo.driver"/>
              <v-textarea class="mb-1" hide-details outlined dense label="Notes" v-model="serialDialog.data.deliveryInfo.notes"/>
              <v-btn color="success" small class="pt-0 mt-0" @click="updateDeliveryInfo">Save</v-btn>
            </span>
            <span class="ml-2 pa-3 d-flex flex-column" style="border: 1px solid #eee; border-radius: 5px; width: 500px;">
              <v-textarea rows="8" disabled label="Order Notes" outlined dense hide-details class="mb-1" v-model="serialDialog.data.metadata.note"/>
<!--              <v-btn color="success" small class="pt-0 mt-0" @click="updateDeliveryInfo">Save</v-btn>-->
            </span>
          </span>
          <span style="border: 1px solid #ff8080; border-radius: 5px; width: 808px;" class="mt-2 pa-3 red--text d-flex flex-column" v-if="(!serialDialog.data.deliveryInfo.status || serialDialog.data.deliveryInfo.status<1) && serialDialog.existing && (serialDialog.existing.existingOS?.length>0)">
            <span class="red--text"><b>Errors/Conflicts</b></span>
            <span>The following {{serialNaming.pluralLower}} have had conflicts and were ignored.</span>
            <span v-if="serialDialog.existing.existingOS?.length>0">
              <span>Used in other orders previously:</span>
              <span>
                <table id="existingSerials">
                  <thead>
                    <tr>
                      <th>Order</th><th>{{ serialNaming.singular }}</th><th>Dispatch</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(serial, s) of serialDialog.existing.existingOS" :key="s" :style="`cursor: pointer; ${serial.orderId===serialDialog.data.id?'background: skyblue; color:black;':''} `">
                      <td @click="$router.push(`/orders/view/${serial.orderId}`)">#{{serial.orderId}}</td>
                      <td>{{serial.ProductSerial?.serial||`Unknown ${serialNaming.singular}`}}</td>
                      <td><v-btn v-if="serialDialog.data.id!==serial.orderId" @click="openSerialDialogFromError($event, serial.orderId)" small color="info">Open Dispatch</v-btn><span v-else @click="openSerialDialogFromError(null, serialDialog.existing.viewingFrom)">Viewing From Dispatch #{{serialDialog.existing?.viewingFrom}}</span></td>
                    </tr>
                  </tbody>
                </table>
              </span>
            </span>
          </span>
        </v-card-text>
        <v-card-text v-if="serialDialog.data" style="overflow-y: auto; max-height: 600px;">
          <h3>Products</h3>
          <v-data-table
              :headers="serialDialog.headers"
              :items="serialDialog.data.OrderLineItems"
          >
            <template v-slot:item.quantity="{ item }">
              <span v-if="item.metadata.quantities" class="d-flex flex-column">
                <span v-for="(loc, i) of item.metadata.quantities" :key="i">{{lookupBranch(loc.locationId)}} - {{loc.quantity}}</span>
                <span><b>Total: {{item.quantity}}</b></span>
              </span>
              <span v-else>{{ item.quantity }}</span>
            </template>
            <template v-slot:item.productId="{ item }">
              <router-link v-if="isAllowed('product', 'u') && item.productId != 'adhoc'" :to="'/products/view/'+item.productId">{{item.productId}}</router-link>
              <span v-else>{{ item.productId }}</span>
            </template>
            <template v-slot:item.productName="{ item }">
              <span>{{ `${item.Product?.Brand?.name||''}${item.Product.name}` }}</span>
            </template>
            <template v-slot:item.serials="{ item }">
              <span>
                <span class="pt-1 d-flex flex-row justify-space-between">
                  <v-combobox
                      :class="item.Product?.metadata?.requireSerials?'mb-n1':'mb-n6'"
                      v-model="item.Product.ProductSerials"
                      :hide-no-data="true"
                      :items="[]"
                      label=""
                      multiple
                      outlined
                      dense
                      chips
                      :disabled="serialDialog.data.deliveryInfo.status>=1"
                      deletable-chips
                      clearable
                      :append-icon="null"
                      @change="syncLineItem(item)"
                      item-text="serial"
                      item-value="serial"
                      :hint="item.Product?.metadata?.requireSerials?`${serialNaming.plural} are required for this product.`:''"
                      :persistent-hint="item.Product?.metadata?.requireSerials"
                  >
                  </v-combobox>
                  <span class="ml-1" style="width: 40px;"><span>{{item.Product.ProductSerials?.length||0}}</span>/<span>{{item.quantity}}</span></span>
                </span>
              </span>
            </template>
          </v-data-table>
          <span v-if="!serialDialog.data.deliveryInfo.status || serialDialog.data.deliveryInfo.status<1" class="red--text"><b>You are not allowed to enter {{serialNaming.pluralLower}} after printing the delivery note.</b></span>
        </v-card-text>
        <v-progress-circular v-else color="success" indeterminate/>
        <v-card-actions v-if="serialDialog.data" class="d-flex flex-row">
          <dynamicButtonContainer @res="getPrintResponse" v-if="getGlobalValue('VEC_PRINT_SYSTEM_CONTAINER_DISPATCH_DIALOG') && serialDialog.data && (!serialDialog.data.deliveryInfo.status || serialDialog.data.deliveryInfo.status<=1)" :containerId="`${getGlobalValue('VEC_PRINT_SYSTEM_CONTAINER_DISPATCH_DIALOG')}`" :data="{disabled: !canPrint, id1: serialDialog.data.id, status: serialDialog.data.deliveryInfo.status}"/>
          <v-btn v-if="serialDialog.data.deliveryInfo.status===1" color="success" @click="confirmDelivery" :loading="serialDialog.isConfirming">Confirm Completed</v-btn>
          <v-btn color="error" @click="closeSerialDialog" @close="closeSerialDialog">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<style>
#existingSerials{
  border-collapse: collapse;
}
#existingSerials td, #existingSerials th{
  border: 1px solid #ff8080;
  padding: 5px;
}
#existingSerials tbody tr:hover{
  background: #f50000;
  color: white;
}
</style>
<script>
  import axios from 'axios';
  import { mapGetters } from "vuex"
  import utils from "../../plugins/helpers"
  import dynamicButtonContainer from "../../components/dynamicButtonContainer.vue";
  export default {
    components: {
      dynamicButtonContainer
    },
    data () {
      return {
        utils: utils,
        serialSearch: '',
        serialSearchResults: [],
        deleteDialog: false,
        deleteConfirmed: true,
        loader: true,
        snackObj: {
          state: false,
          color: '',
          text: ''
        },
        tableTitle: '',
        max25chars: v => v.length <= 25 || 'Input too long!',
        headers: [
          {
            text: '#',
            align: 'start',
            sortable: true,
            value: 'id',
          },
          { text: 'Status', value: 'status' },
          { text: 'Date Sealed', value: 'sealedAt' },
          { text: 'Customer', value: 'customerName', sortable: false },
          { text: 'Phone', value: 'customerPhone', sortable: false },
          { text: 'Address', value: 'address', sortable: false },
          { text: 'CSR', value: 'csrUser', sortable: false },
          { text: 'Branch', value: 'branchId', sortable: false },
          { text: '# Items', value: 'itemCount', sortable: false},
          { text: 'Actions', value: 'actions', sortable: false}
        ],
        editMode: false,
        orders: [],

        pagination: {
          loading: false,
          dataCount: 0,
          pageSizeOptions: [5, 10, 15, 30, -1]
        },
        options: {
          search: "",
          sortBy: ['id'],
          sortDesc: [true],
          page: 1,
          itemsPerPage: 15,
          selectedBranches: [],
        },
        updatingRoute: false,
        init: false,

        allBranches: [],
        allBranchesSelected: true,

        serialDialog: {
          isOpen: false,
          isLoading: false,
          isSyncing: false,
          isConfirming: false,
          selected: null,
          data: null,
          existing: null,
          headers: [
            {
              text: 'QTY',
              align: 'start',
              sortable: false,
              value: 'quantity',
            },
            {
              text: 'Product #',
              align: 'start',
              sortable: false,
              value: 'productId',
            },
            { text: 'Name', value: 'productName', sortable: false },
            { text: 'Serials', value: 'serials', sortable: false }
          ]
        },

        singular: "Dispatch",
        singularLower: "dispatch",
        plural: "Dispatches",
        pluralLower: "dispatches",
      }
    },
    async created(){
      try{
        //used for pagination
        let oldQuery = this.getURLQuery();
        this.options.page = oldQuery.page || 1;
        this.options.itemsPerPage = oldQuery.limit || 15
        this.options.sortBy = [oldQuery.sort || "createdAt"]
        this.options.sortDesc = [oldQuery.order || true]
        this.options.search = oldQuery.search || ""
        this.options.selectedBranches = oldQuery.selectedBranches || []

        if(this.options.selectedBranches.length>0) this.allBranchesSelected = false;

        //used for pagination end

        let i = this.serialDialog.headers.findIndex(x => x.value==='serials');

        if(this.getGlobalValue('replaceProductSerialsWithIMEI')==='true'){
          if(i>=0){
            this.serialDialog.headers[i].text = this.serialNaming.plural
          }
        }
        if(this.getGlobalValue('productSerialShowOnDispatch')!=='true'){
          i = this.serialDialog.headers.findIndex(x => x.value==='serials');
          if(i>=0){
            this.serialDialog.headers.splice(i);
          }

        }
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error.msg?.message || error, "error");
      }
    },
    async mounted(){
        try {
          this.init = true;

          this.pagination.pageSizeOptions = this.getGlobalValue("paginationPageLimitsGeneral")?.split(",").map(x => parseInt(x)) || this.pagination.pageSizeOptions;

          if(this.isAllowed('dispatch', 'excludeBranchFilter')){
            this.allBranches = this.getBranches.map(x => {
              return {
                label: x.name,
                value: x.id
              }
            });
          }
          else{
            this.allBranches = this.getUser?.metadata?.branchData?.allowed.map(x => {
              return {
                label: this.lookupBranch(x),
                value: x
              };
            });
          }

          await this.getAllData();
          this.init = false;
        } catch (error) {
          console.error(error);
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
            this.loader = false;
        }
    },
    computed: {
      ...mapGetters(['getId', 'getEndpoint', 'lookupUsername', 'getGlobalValue', 'lookupBranch', 'getBranches', 'isAllowed', 'getUser', 'serialNaming']),
      canPrint(){
        if(!this.serialDialog.data) return false;
        if(this.serialDialog.isLoading) return false;
        if(this.serialDialog.isSyncing) return false;
        for(let oli of this.serialDialog.data.OrderLineItems){
          if(!oli.Product.metadata?.requireSerials) continue;
          if(!oli.Product.ProductSerials) return false;
          if(oli.Product.ProductSerials.length===0) return false;
          if(oli.Product.ProductSerials.length!==oli.quantity) return false;
        }

        return true
      }
    },
    watch: {
      options: {
        async handler () {
          await this.getAllData();
        },
        deep: true,
      },
      $route: {
        handler: async function (current) {
          //used for pagination
          if (this.updatingRoute) return;
          let oldQuery = this.getURLQuery(current.query);
          this.options.page = oldQuery.page || 1;
          this.options.itemsPerPage = oldQuery.limit || 15
          this.options.sortBy = [oldQuery.sort || "createdAt"]
          this.options.sortDesc = [oldQuery.order || true]
          this.options.search = oldQuery.search || ""
          this.options.selectedBranches = oldQuery.selectedBranches || []
          //used for pagination end include full watcher
        },
        deep: true
      }
    },
    methods: {
      getURLQuery(custom=null){
        let oldQuery = custom? {...custom}: {...this.$route.query};
        if(oldQuery.limit) oldQuery.limit = parseInt(oldQuery.limit);
        if(oldQuery.page) oldQuery.page = parseInt(oldQuery.page);
        if(oldQuery.order) oldQuery.order = oldQuery.order==='true';
        if(oldQuery.selectedBranches){
          if(Array.isArray(oldQuery.selectedBranches)) oldQuery.selectedBranches = oldQuery.selectedBranches.map(x => parseInt(x))
          else oldQuery.selectedBranches = [parseInt(oldQuery.selectedBranches)]
        }
        return oldQuery;
      },
      rowClick(row){
        this.$router.push({ path: `/dispatch/view/${row.id}`})
      },
      snack(text, color=""){
        this.snackObj.text = text;
        this.snackObj.state = true;
        this.snackObj.color = color;
      },
      async getAllData(){
        try{
          //used for pagination
          this.pagination.loading = true;
          let paginationData = {
            page: this.options.page,
            limit: this.options.itemsPerPage,
            sort: (this.options.sortBy.length>0)?this.options.sortBy[0]:"id",
            order: this.options.sortDesc[0],
            search: this.options.search,
            selectedBranches: this.options.selectedBranches
          }
          let uriFields = Object.keys(paginationData).map(x => {
            return x + "=" + (paginationData[x]!==undefined?encodeURIComponent(paginationData[x]):'')
          }).join("&");
          //used for pagination end

          let res = await axios.get(`${this.getEndpoint}/api/dispatch/tableDataPaginated?${uriFields}`)
          if(res.data.error) throw res.data.error
          this.orders = res.data.data.map(x => {
            return {
              ...x,
              isLoading: false
            }
          })

          //used for pagination
          this.pagination.dataCount = res.data.total
          let shouldUpdate = false;
          let oldQuery = this.getURLQuery();
          let newQuery = {...paginationData};
          if(Object.keys(newQuery).length!==Object.keys(oldQuery).length) shouldUpdate = true;
          if(!shouldUpdate){
            for(let key of Object.keys(newQuery)){
              if(shouldUpdate) break;

              if(Array.isArray(newQuery[key])){
                if(newQuery[key].length!==oldQuery[key].length){
                  shouldUpdate = true;
                  break;
                }
                for(let i=0;i<newQuery[key].length-1;i++){
                  if(newQuery[key][i]!==oldQuery[key][i]){
                    shouldUpdate = true;
                    break;
                  }
                }
              }
              else if(newQuery[key]!==oldQuery[key]){
                shouldUpdate = true;
              }
            }
          }

          if(shouldUpdate){
            this.updatingRoute = true;
            if(this.init){
              if(this.$route.path===`/dispatch`) await this.$router.replace({path: `/dispatch`, query: newQuery});
            }
            else{
              if(this.$route.path===`/dispatch`) await this.$router.push({path: `/dispatch`, query: newQuery});
            }
            this.updatingRoute = false;
          }
          //used for pagination end
        } catch (error) {
          console.error(error);
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.pagination.loading = false;
          this.loader = false;
        }
      },
      updateBranchFilter(id){
        if(id===0){
          if(this.allBranchesSelected){ //the value is already being set true by the component
            this.options.selectedBranches = [];
          }
          else{
            this.options.selectedBranches = this.allBranches.map(x => x.value);
          }
        }
        else{
          if(this.options.selectedBranches.length===0) this.allBranchesSelected = true;
          else this.allBranchesSelected = false;
        }
      },
      async openSerialDialogFromError(event, id){
        if(event) event.stopPropagation();
        this.serialDialog.existing.viewingFrom = this.serialDialog.data.id;
        this.closeSerialDialog(true);
        await this.openSerialDialog({id: id})
      },
      async openSerialDialog(item){
        try{
          item.isLoading = true;

          await this.getOrderData(item.id)

          this.serialDialog.isOpen = true;
        }
        catch (error) {
          console.error(error);
          this.snack(error.msg || error.msg?.message || error, "error");
        }
        finally {
          item.isLoading = false;
        }
      },
      closeSerialDialog(keepExisting=false){
        this.serialDialog.isOpen = false;
        this.serialDialog.selected = null;
        this.serialDialog.data = null;
        if(!keepExisting) this.serialDialog.existing = null;
      },
      async getOrderData(id){
        let res = await axios.get(`${this.getEndpoint}/api/dispatch/serials/${id}`)
        if(res.data.error) throw res.data.error
        this.serialDialog.data = res.data.data

        console.log(this.serialDialog)
      },
      async syncLineItem(item){
        try{
          this.serialDialog.isSyncing = true;

          if(item.Product?.ProductSerials?.length>item.quantity){
            item.Product.ProductSerials.splice(item.quantity);
            throw `Max number of ${this.serialNaming.pluralLower} for this line item.`
          }

          item.Product.ProductSerials = item.Product.ProductSerials.map(x => {
            if(typeof x === "string"){
              return {
                productId: item.productId,
                serial: x.trim()
              }
            }
            else{
              return x
            }
          });

          let res = await axios.put(`${this.getEndpoint}/api/dispatch/serials/${item.orderId}`, {productId: item.productId, serials: item.Product.ProductSerials})
          if(res.data.error) throw res.data.error
          console.log(res.data.data)
          this.serialDialog.existing = res.data.data
          if(this.serialDialog.existing.createdPS?.length>0){
            this.snack(`${this.serialNaming.singular} ${this.serialDialog.existing.createdPS[0].serial} was not found and was created for Product #${this.serialDialog.existing.createdPS[0].productId}.`, "success")
          }
          if(this.serialDialog.existing.notCreatedPS?.length>0){
            this.snack(`${this.serialNaming.singular} ${this.serialDialog.existing.notCreatedPS[0].serial} was not found for Product #${this.serialDialog.existing.notCreatedPS[0].productId}.`, "error")
          }

          await this.getOrderData(item.orderId);
        }
        catch (error) {
          console.error(error);
          this.snack(error.msg || error.msg?.message || error, "error");
        }
        finally {
          this.serialDialog.isSyncing = false;
        }
      },
      getPrintResponse(res){
        try{
          if(res.data.error) throw res.data.error

          let o = this.orders.find(x => x.id===this.serialDialog.data.id);
          if(o){
            o.deliveryInfo.status = 1;
          }

          this.snack("Delivery now in progress.", "success");

          this.closeSerialDialog();
        }
        catch (error) {
          console.error(error);
          this.snack(error.msg || error.msg?.message || error, "error");
        }
      },
      async confirmDelivery(){
        try{
          this.serialDialog.isConfirming = true;

          let res = await axios.put(`${this.getEndpoint}/api/dispatch/confirm/${this.serialDialog.data.id}`)
          if(res.data.error) throw res.data.error

          this.snack("Delivery Confirmed.", "success");

          let o = this.orders.find(x => x.id===this.serialDialog.data.id);
          if(o){
            console.log(o)
            o.deliveryInfo.status = 2;
          }

          this.closeSerialDialog();
        }
        catch (error) {
          console.error(error);
          this.snack(error.msg || error.msg?.message || error, "error");
        }
        finally{
          this.serialDialog.isConfirming = false;
        }
      },
      async updateDeliveryInfo(){
        try{
          this.serialDialog.isSyncing = true;

          let res = await axios.put(`${this.getEndpoint}/api/dispatch/${this.serialDialog.data.id}`, {driver: this.serialDialog.data.deliveryInfo.driver||'', notes: this.serialDialog.data.deliveryInfo.notes||''})
          if(res.data.error) throw res.data.error

          this.snack("Updated successfully", "success");
        }
        catch (error) {
          console.error(error);
          this.snack(error.msg || error.msg?.message || error, "error");
        }
        finally{
          this.serialDialog.isSyncing = false;
        }
      },
      async searchSerial(){
        try{
          this.loader = true
          let res = await axios.post(`${this.getEndpoint}/api/${this.singularLower}/searchSerial`, {query: this.serialSearch})
          if(res.data.error) throw res.data.error
          this.serialSearchResults = res.data.data
          console.log(this.serialSearchResults)
        } catch (error) {
          console.error(error);
        } finally {
          this.loader = false;
        }
      },
    }
  }
</script>
